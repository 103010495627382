* {
  margin: 0;

  padding: 0;

  border: 0;
}

.App {
  height: 200vh;
  background-color: black;
  width: calc(100vw - (100vw - 100%));
}

.App-title {
  height: 100vh;
  width: calc(100vw - (100vw - 100%));
  position: absolute;
  z-index: 5;
  mask: url('ralph-mask.png') center;
  mask-size: calc(100vw - (100vw - 100%)) 100vh;
  mask-repeat: no-repeat;
}

.App-image-container {
  display: flex;
  height: 100vh;
  z-index: 5;
  justify-content: center;
  align-items: center;
}

.App-image {
  max-height: 100%;
  max-width: 100%;
  position: fixed;
}

.Gallery-button {
  position: fixed;
  z-index: 2;
  font-size: calc(14px + 0.45vw);
  margin: 5px;
  padding: 5px;
  color: #fff;
  text-shadow: 2px 2px #333;
}

.Info-button {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  font-size: calc(14px + 0.45vw);
  margin: 5px;
  padding: 5px;
  color: #fff;
  text-shadow: 2px 2px #333;
}

.Left-arrow {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  font-size: calc(14px + 0.45vw);
  margin: 5px;
  padding: 5px;
  color: #fff;
  text-shadow: 2px 2px #333;
}

.Right-arrow {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  font-size: calc(14px + 0.45vw);
  margin: 5px;
  padding: 5px;
  color: #fff;
  text-shadow: 2px 2px #333;
}

ul {
  list-style-type: none;
}

.List-item {
  padding: 3px;
  margin: 3px;
}

.Info-text {
  color: #fff;
  width: 40vw;
  margin: 25px 25vw;
  padding: 25px;
  overflow: scroll;
  justify-content: center;
}
.Info-text::-webkit-scrollbar {
  display: none;
}

.App-catagory {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 3px;
  margin: 3px;
}

.Drawer-nav {
  display: flex;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 3px;
  margin: 20px 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.Modal-background {
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 100%;
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal-content {
  width: 900px;
  height: 500px;
  background-color: #fff;
  color: #000;
  opacity: 100%;
  z-index: 4;
  overflow: scroll;
  padding: 25px 25px;
}
.Modal-content::-webkit-scrollbar {
  display: none;
}
.Modal-body {
  margin: 16px;
}

.Close-button {
  color: #fff;
  font-size: calc(14px + 0.45vw);
  margin: 5px;
  padding: 5px;
  position: fixed;
  top: 0;
  right: 0;
}

.Modal-close-button {
  color: #000;
  font-size: calc(14px + 0.45vw);
  margin: 5px;
  padding: 5px;
  text-align: right;
}

.Nav-button {
  display: flex;
  background-color: #fff;
  border: none;
  color: #000;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
}

.Catagory-heading {
  font-size: 18px;
  color: #fff;
  padding: 16px;
}

.Statement-image {
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  width: inherit;
}
